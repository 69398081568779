import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HeaderLogo from "./header-logo";
import { Button, ButtonRow, ButtonWrapperRightAligned } from "./button-elements";

const LeftMenu = styled.div`
  position: fixed;
  top: 81px;
  bottom: 0px;
  left: 0;
  width: 249px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: calc(160px - 4px);
`;

const RightMenu = styled.div`
  position: fixed;
  top: 81px;
  bottom: 0px;
  left: 0;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: 200px;
`;

const Header = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 48px;
  padding-right: 48px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

const BottomMenu = styled.div`
  position: fixed;
  bottom: 0px;
  left: 250px;
  right: 0px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // padding-bottom: 16px;
  // padding-top: 16px;
  padding-left: 16px;
  // padding-right: 48px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;

const TopMenu = styled.div`
  position: fixed;
  top: 81px;
  left: 250px;
  right: 0px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

const Page = styled.div`
  position: fixed;
  top: calc(80px + 80px + 2px);
  // bottom: calc(68px + 1px);
  left: 250px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
`;

const MenuItem = styled(Link)`
  box-sizing: border-box;
  width: calc(250px - 16px - 16px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: ${props => props.selected ? "black" : "rgba(0, 0, 0, 0.03)"};
  color: ${props => props.selected ? "white" : "black"};
  &:hover {
    background-color: ${props => props.selected ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.08)"};
    color: ${props => props.selected ? "white" : "black"};
    cursor: pointer;
  }
`;

export default function PageTemplate(props) {

  const { leftMenuContent, rightMenuContent, bottomMenuContent, topMenuContent, pageContent } = props;

  let bottomMenu;
  let topMenu;
  let leftMenu;
  let rightMenu;
  let page;

  let navMenu =
    <ButtonRow>
      <ButtonWrapperRightAligned>
        <Button inverted>
          Subscribe
        </Button>
      </ButtonWrapperRightAligned>
      <ButtonWrapperRightAligned>
        <Button>
          Sign up
        </Button>
      </ButtonWrapperRightAligned>
      <ButtonWrapperRightAligned>
        <Button>
          Sign in
        </Button>
      </ButtonWrapperRightAligned>
    </ButtonRow>

  // let navMenu =
  //   <ButtonRow>
  //     <ButtonWrapperRightAligned>
  //       <Button inverted component={Link} to="/assistant">
  //         AI Assistant
  //       </Button>
  //     </ButtonWrapperRightAligned>
  //     <ButtonWrapperRightAligned>
  //       <Button component={Link} to="/search">
  //         AI Search
  //       </Button>
  //     </ButtonWrapperRightAligned>
  //     <ButtonWrapperRightAligned>
  //       <Button component={Link} to="/analysis">
  //         AI Analysis
  //       </Button>
  //     </ButtonWrapperRightAligned>
  //     <ButtonWrapperRightAligned>
  //       <Button component={Link} to="/summarize">
  //         AI Summarize
  //       </Button>
  //     </ButtonWrapperRightAligned>
  //   </ButtonRow>

  if (bottomMenuContent) {
    bottomMenu =
      <BottomMenu>
        {bottomMenuContent}
      </BottomMenu>
  }

  if (topMenuContent) {
    topMenu =
      <TopMenu>
        {topMenuContent}
      </TopMenu>
  }

  // if (leftMenuContent) {
  //   leftMenu =
  //     <LeftMenu>
  //       {leftMenuContent}
  //     </LeftMenu>
  // }

  leftMenu =
    <LeftMenu>
      <MenuItem to="/">
        Home
      </MenuItem>
      <MenuItem to="/ask" selected={window.location.pathname === ("/ask")}>
        Ask
      </MenuItem>
      <MenuItem to="/search" selected={window.location.pathname === ("/search")}>
        Search
      </MenuItem>
      <MenuItem to="/visualize" selected={window.location.pathname === ("/visualize")}>
        Visualize
      </MenuItem>
      <MenuItem to="/read" selected={window.location.pathname === ("/read")}>
        Read
      </MenuItem>
    </LeftMenu>

  if (rightMenuContent) {
    rightMenu =
      <RightMenu>
        {rightMenuContent}
      </RightMenu>
  }

  if (pageContent) {
    page =
      <Page>
        {pageContent}
      </Page>
  }

  const header =
    <Header>
      <HeaderLogo />
      {navMenu}
    </Header>

  return (
    <>
      {page}
      {header}
      {bottomMenu}
      {topMenu}
      {leftMenu}
      {rightMenu}
    </>
  );
};
