import { useEffect, useRef } from "react";
import ChatItem from "./chat-item";
import styled from "styled-components";

const ChatWrapper = styled.div`
  position: fixed;
  top: 162px;
  bottom: calc(48px + 160px + 24px);
  left: 250px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 48px;
  overflow-y: scroll;
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 700px;
`;

export default function ChatMessages({
  messages,
  isLoading,
  reload,
  stop,
}) {
  const scrollableChatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (scrollableChatContainerRef.current) {
      scrollableChatContainerRef.current.scrollTop =
        scrollableChatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  const firstMessage = {
    content: "Hello! I'm an AI legal assistant who specializes in EU law. What can I help you with today?",
    role: "assistant"
  }

  // console.log(messages)

  return (
    <ChatWrapper>
      <MessagesWrapper ref={scrollableChatContainerRef}>
        <ChatItem {...firstMessage} />
        {messages.map((m) => (
          <ChatItem key={m.id} {...m} />
        ))}
      </MessagesWrapper>
    </ChatWrapper>
  );
}
