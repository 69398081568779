import * as d3 from 'd3';
import * as d3zoom from 'd3-zoom';
import * as d3selection from 'd3-selection';
import { renderToString } from 'react-dom/server'
import styled from "styled-components";

const Document = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: solid 2px #A28BFA;
  top-right-border-radius: 6px;
  bottom-right-border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
`;

const Document0 = styled(Document)`
  color: rgba(255, 255, 255, 0.9);
  background: #334150;
  border-left: solid 4px #334150;
`;

const Document1 = styled(Document)`
  color: #292E31;
  background: #EDEEF7;
  border-left: solid 4px #A28BFA;
`;

const Document2 = styled(Document)`
  color: #292E31;
  background: #E1F5F5;
  border-left: solid 4px #28CFCA;
`;

const Node = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
`;

const Node0 = styled(Node)`
  margin-top: 62px;
  color: #205ADF;
  background: #A6BDF2;
  // border: solid 2px #334150;
`;

const Node1 = styled(Node)`
  margin-top: 62px;
  color: #205ADF;
  background: #E5EDFF;
  // border: solid 1px #A28BFA;
  // background: rgba(255, 255, 255, 0.8);
  // border: solid 1px rgba(0, 0, 0, 0.1);
`;

const Node2 = styled(Node)`
  margin-top: 62px;
  color: #205ADF;
  background: #F5F8FF;
  // border: solid 1px #28CFCA;
  // background: rgba(255, 255, 255, 0.8);
  // border: solid 1px rgba(0, 0, 0, 0.1);
`;

const CaseNumber = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 4px;
`;

const CaseName = styled.div`
  font-size: 10px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const runNetworkGraph = (container, linksData, nodesData, callback) => {

  const links = linksData.map((d) => Object.assign({}, d));
  const nodes = nodesData.map((d) => Object.assign({}, d));

  const containerRect = container.getBoundingClientRect();
  const height = containerRect.height;
  const width = containerRect.width;

  const dragStarted = (event, d) => {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  };

  const dragged = (event, d) => {
    d.fx = event.x;
    d.fy = event.y;
    d.fixed = true;
  };

  const dragEnded = (event, d) => {
    if (!event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  };

  const collideRadius = (d) => {
    return (4 - d.source.level);
  }

  const simulation = d3
    .forceSimulation()
    .force("charge", d3.forceManyBody().strength(-2000))
    .force("center", d3.forceCenter(width / 2, height / 2))
    .force("link", d3.forceLink().id(d => d.id).distance(1000).strength(2))
    .force("collide", d3.forceCollide().strength(1).radius(90).iterations(1))

  const zoomContainer = d3
    .select(container)
    .append("div")
    .attr("id", "zoom-container")
    .classed("container", 1)
    .style("position", "absolute")
    .style("width", "100%")
    .style("height", "100%")

  const svg = zoomContainer
    .append("svg")
    .classed("simulation", 1)
    .style("position", "absolute")
    .style("pointer-events", "none")
    .style("width", width)
    .style("height", height)
  // .style("background-color", "rgba(0, 0, 0, 0.02)")

  var glink = svg
    .append("g")
  // .attr("transform", "translate(0, 0) scale(1)")

  var link = glink
    .attr("class", "links")
    .selectAll("links")
    .data(links)
    .enter()
    .append("path")
    .attr('fill-opacity', 0)
    .attr('stroke-width', 1)
    .attr('stroke', '#E5EDFF')
    .attr('id', (d, i) => 'links' + i)
    .attr('marker-mid', 'url(#arrowhead)')

  // var linklabels = svg.selectAll("edgelabel")
  //   .data(links)
  //   .enter()
  //   .append('text')
  //   .attr({
  //     'class': 'edgelabel',
  //     'id': function (d, i) { return 'edgelabel' + i },
  //     'dx': 80,
  //     'dy': 0,
  //     'font-size': 10,
  //     'fill': '#aaa'
  //   });

  // linklabels.append('textPath')
  //   .attr('xlink:href', function (d, i) { return '#edgepath' + i })
  //   .style("pointer-events", "none")
  //   .text(function (d, i) { return 'label ' + i });

  // var arrow = glink.append('defs').append('marker')
  //   .attr('id', 'arrowhead')
  //   .attr('viewBox', '-0 -5 10 10')
  //   .attr('refX', 30)
  //   .attr('refY', 0)
  //   .attr('orient', 'auto')
  //   .attr('markerWidth', 10)
  //   .attr('markerHeight', 10)
  //   .attr('xoverflow', 'visible')
  //   .append('svg:path')
  //   .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
  //   .attr('fill', '#999')
  //   .style('stroke', 'none');

  const makeNodeHTML = (d) => {

    let node;

    const content =
      <div>
        {/* <Node>
          {d.level}
        </Node> */}
        <CaseNumber>
          {d.cjeu_proceeding_id}
        </CaseNumber>
        <CaseName>
          {d.proceeding_name}
        </CaseName>
      </div>

    if (d.type === "document" & d.level === 0) {
      node =
        <Node0 style={{ backgroundColor: "black" }}>
          {content}
        </Node0>
    }

    if (d.type === "document" & d.level === 1) {
      node =
        <Node1 style={{ backgroundColor: "black" }}>
          {content}
        </Node1>
    }

    if (d.type === "document" & d.level === 2) {
      node =
        <Node2>
          {content}
        </Node2>
    }

    return (
      node
    )
  }

  // var linkedByIndex = {};
  // links.forEach(function (d) {
  //   linkedByIndex[d.source + "," + d.target] = 1;
  // });

  // function isConnected(a, b) {
  //   console.log(a)
  //   console.log(b)
  //   return linkedByIndex[a.id + "," + b.id] || linkedByIndex[b.id + "," + a.id] || a.id == b.id;
  // }

  var linkedByIndex = {};
  links.forEach(function (d) {
    linkedByIndex[d.source + "," + d.target] = 1;
  });

  const isConnected = (a, b) => {
    return linkedByIndex[a.id + "," + b.id] || linkedByIndex[b.id + "," + a.id] || a.id == b.id;
  }

  const fade = (d) => {

    node.style("opacity", function (o) {
      return isConnected(d, o) ? 1 : 0.3;
    });

    // node.style("background-color", function (o) {
    //   return isConnected(d, o) ? "#EEF4FC" : "#F8F8F8";
    // });

    // node.style("border-color", function (o) {
    //   return isConnected(d, o) ? "rgba(0, 0, 0, 1)" : "rgba(240, 240, 240, 1)";
    // });

    // node.style("color", function (o) {
    //   return isConnected(d, o) ? "#355E95" : "#D8D8D8";
    // });

    node.sort(function (a, b) {
      if (a == d || isConnected(a, d)) return 1;
      else return -1;
    });

    // link.style("opacity", function (o) {
    //   return o.source.id === d.id || o.target.id === d.id ? 1 : 0.2;
    // });

    link.style("stroke", function (o) {
      return o.source.id === d.id || o.target.id === d.id ? "#205ADF" : "#E5EDFF";
    });

    link.style("stroke-width", function (o) {
      return o.source.id === d.id || o.target.id === d.id ? 1 : 1;
    });

    link.sort(function (a) {
      if (a.source.id === d.id || a.target.id === d.id) return 1;
      else return -1;
    });
  }

  const unfade = (d) => {

    node.style("opacity", 1);

    // node.style("background-color", "#EEF4FC");

    // node.style("border-color", "rgba(240, 240, 240, 1)");

    // node.style("color", "#355E95");

    link.style("stroke", "#E5EDFF");

    link.style("stroke-width", 1);

    // link.style("opacity", 1);
  }

  const node = zoomContainer
    .append("div")
    .attr("class", "nodes")
    .style("position", "absolute")
    .selectAll(".node")
    .data(nodes)
    .enter()
    .append("div")
    .attr("class", "node")
    .attr("id", d => d.id)
    .style("position", "absolute")
    .style("cursor", "pointer")
    // .style("border-style", "solid")
    // .style("border-width", "2px")
    // .style("border-radius", "8px")
    // .style("background-color", "rgba(250, 250, 250, 1)")
    // .style("border-color", "rgba(240, 240, 240, 1)")
    .html(d => renderToString(makeNodeHTML(d)))

  node.call(d3.drag()
    .on("start", dragStarted)
    .on("drag", dragged)
    .on("end", dragEnded));

  // runs while zooming
  const onZoom = (event) => {

    glink.attr("transform", "translate(" + event.transform.x + "," + event.transform.y + ") scale(" + event.transform.k + ")");

    d3.select(".nodes").style("transform", "translate(" + event.transform.x + "px," + event.transform.y + "px) scale(" + event.transform.k + ")");

    d3.select(".nodes").style("transform-origin", "0px 0px");

    simulation.alphaTarget(0.001).restart();
    simulation.alphaTarget(0);
  }

  // function to handle zooming
  var zoom = d3zoom.zoom()
    // .extent([[0, 0], [width, height]])
    .scaleExtent([0.1, 4])
    .on("zoom", onZoom)

  // call the zoom function
  zoomContainer.call(zoom)
  // d3.select("#network-container").call(zoom)
  // svg.call(zoom)

  const onMouseEnter = (event, d) => {
    callback(d.id)
    fade(d);
  }

  const onMouseLeave = (event, d) => {
    callback(undefined)
    unfade(d);
  }

  node
    .on("mouseenter", onMouseEnter)
    .on("mouseleave", onMouseLeave)

  // simulation.on("tick", () => {

  //   link
  //     .attr("x1", d => d.source.x)
  //     .attr("y1", d => d.source.y)
  //     .attr("x2", d => d.target.x)
  //     .attr("y2", d => d.target.y);

  //   node
  //     .style("left", d => d.x - 50 + "px")
  //     .style("top", d => d.y - 25 + "px");

  // });

  const getWidth = (d) => {
    let width = 100;
    // if (d.type === "document") {
    //   width = 100;
    // }
    // if (d.type === "verb") {
    //   width = 100;
    // }
    return (width);
  }

  const getHeight = (d) => {
    let height = 100;
    // if (d.type === "document" & d.level === 0) {
    //   height = 28;
    // }
    // if (d.type === "verb") {
    //   height = 50;
    // }
    return (height);
  }

  const ticked = () => {

    // link
    //   .attr("x1", d => d.source.x)
    //   .attr("y1", d => d.source.y)
    //   .attr("x2", d => d.target.x)
    //   .attr("y2", d => d.target.y);

    // link.attr('d', (d) => {
    //   var path = 'M ' + d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y;
    //   return path
    // });

    link.attr("d", (d) => {
      return 'M ' + d.source.x + ' ' + d.source.y + ' L ' + ((d.source.x + d.target.x) / 2) + ' ' + ((d.source.y + d.target.y) / 2) + ' L ' + d.target.x + ' ' + d.target.y
    });

    node
      .style("left", d => d.x - (getWidth(d) / 2) + "px")
      .style("top", d => d.y - (getHeight(d) / 2) + "px");

  }

  simulation
    .nodes(nodes)
    .on("tick", ticked)

  simulation
    .force("link")
    .links(links)

  return {
    destroy: () => {
      simulation.stop();
    },
    nodes: () => {
      return svg.node();
    }
  };
}

export default runNetworkGraph;
