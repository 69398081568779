import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Image = styled.img`
  height: 50px;
`;

const Logo = () => {

  return (
    <Wrapper>
      <Image src="logo-2.svg"></Image>
    </Wrapper>
  )
}

export default Logo;
