import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MenuButton from "./menu-button";
import Logo from "./logo";
import theme from "./theme";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: unset;
  width: unset;
  height: 96px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 4px;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  @media (min-width: ${theme.breakpoints.computer}) {
    display: none;
  }
`;

const MenuButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Drawer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  z-index: 2000;
  top: 96px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: ${theme.spacing.margins};;
  padding-bottom: ${theme.spacing.margins};
  padding-left: ${theme.spacing.margins};
  padding-right: ${theme.spacing.margins};
  background-color: white;
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => props.open ? "1" : "0"};
  overflow: hidden;
  pointer-events: ${props => props.open ? "unset" : "none"};
`;

const Item = styled(Link)`
  font-size: 24px;
  font-weight: 100;
  // font-style: italic;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 4px;
  margin-top: 18px;
  margin-bottom: 12px;
  padding-bottom: 2px;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
  transition: color 0.3s ease-in-out;
  border-bottom: solid 1px #ffffff00;
  &:hover {
    border-bottom: solid 1px #ffffff;
  }
  @media (min-width: 888px) {
    font-size: 48px;
    border-bottom: solid 2px #ffffff00;
    margin-bottom: 18px;
    &:hover {
      border-bottom: solid 1.5px #ffffff;
    }
  }
`;

const SmallItem = styled(Item)`
  @media (min-width: 888px) {
    font-size: 24px;
  }
`;

const LogoWrapper = styled(Link)`
  position: fixed;
  top: 32px;
  left: 0px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MenuItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: ${props => props.selected ? "black" : "rgba(0, 0, 0, 0.03)"};
  color: ${props => props.selected ? "white" : "black"};
  &:hover {
    background-color: ${props => props.selected ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.08)"};
    color: ${props => props.selected ? "white" : "black"};
    cursor: pointer;
  }
`;

const Menu = (props) => {

  const { userData, signOut } = props;

  const [menuOpen, setMenuOpen] = React.useState(false);

  let drawer;

  if (userData) {
    drawer =
      <Drawer open={menuOpen}>
        <MenuItem as="a" href="mailto:media@lawlibrary.ai" onClick={() => {
          setMenuOpen(false);
        }}>
          Contact us
        </MenuItem>
        <MenuItem as={Link} to="/account" onClick={() => {
          setMenuOpen(false);
        }}>
          Account
        </MenuItem>
        <MenuItem onClick={() => {
          signOut();
          setMenuOpen(false);
        }}>
          Sign out
        </MenuItem>
      </Drawer>
  } else {
    drawer =
      <Drawer open={menuOpen}>
        <MenuItem as="a" href="mailto:media@lawlibrary.ai" onClick={() => {
          setMenuOpen(false);
        }}>
          Contact us
        </MenuItem>
        <MenuItem as={Link} to="/sign-up" onClick={() => {
          setMenuOpen(false);
        }}>
          Sign up
        </MenuItem>
        <MenuItem as={Link} to="/sign-in" onClick={() => {
          setMenuOpen(false);
        }}>
          Sign in
        </MenuItem>
      </Drawer>
  }

  return (
    <Wrapper>
      <Logo
        logoHeight="50px"
        wordmarkHeight="24px"
      />
      <MenuButtonWrapper>
        <MenuButton onClick={() => setMenuOpen(!menuOpen)} open={menuOpen} />
      </MenuButtonWrapper>
      {drawer}
    </Wrapper>
  );
}

export default Menu;
