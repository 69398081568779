import styled from "styled-components";

const Base = styled.div`
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition-duration: 0.2s;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`;

const Blue = styled(Base)`
  background: rgba(69, 170, 242, 0.15);
  color: rgba(69, 170, 242, 1);
  &:hover {
    background: rgba(69, 170, 242, 0.3);
  }
`;

const Red = styled(Base)`
  background: rgba(252, 92, 101, 0.15);
  color: rgba(252, 92, 101, 1);
  &:hover {
    background: rgba(252, 92, 101, 0.3);
  }
`;

const Green = styled(Base)`
  background: rgba(38, 222, 129, 0.15);
  color: rgba(38, 222, 129, 1);
  &:hover {
    background: rgba(38, 222, 129, 0.3);
  }
`;

const Chip = (props) => {

  const { value, type } = props;

  let chip =
    <Base>
      {value}
    </Base>
  if (type === "topic") {
    chip =
      <Blue>
        {value}
      </Blue>
  }
  if (type === "actor") {
    chip =
      <Red>
        {value}
      </Red>
  }
  if (type === "concept") {
    chip =
      <Green>
        {value}
      </Green>
  }

  return (
    chip
  )

}

export default Chip;
