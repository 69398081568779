import ChatAvatar from "./chat-avatar";
import styled from "styled-components";

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;

const InnerMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
`;

const MessageText = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  line-height: 1.7;
  font-weight: 300;
`;

const Citation = styled.div`
  box-sizing: border-box;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: calc(56px + 24px + 48px);
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background-color: rgba(0, 0, 0, 0.02);
  font-size: 14px;
  font-weight: 300;
  color: black;
  line-height: 1.5;
`;

export default function ChatItem(message) {

  let citation;
  if (message.role !== "user" && message.content.includes("C-333/21")) {
    citation =
      <Citation>
        <span><b>Source: </b>Judgment of 21 December 2023, C-333/21, EU:C:2023:1011.</span>
      </Citation>
  }

  return (
    <MessageWrapper>
      <InnerMessageWrapper>
        <ChatAvatar {...message} />
        <MessageText>{message.content}</MessageText>
      </InnerMessageWrapper>
      {citation}
    </MessageWrapper>
  );
}
