import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";

import Header from "./header";
import Footer from "./footer";

import LandingPage from "./landing-page";
import SignInPage from "./sign-in-page";
import SignUpPage from "./sign-up-page";
import WelcomePage from "./welcome-page";
import VerifyEmailPage from "./verify-email-page";
import VerifyEmailExpiredLinkPage from "./verify-email-expired-link-page";
import AccountPage from "./account-page";
import HomePage from "./home-page";

import PricingPage from "./pricing-page";
import ViewDocumentPage from "./reading-room-page";
import NetworkPage from "./network-page";
import CompareDocumentsPage from "./compare-documents-page";
import TestPage from "./test-page";
import ChatbotPage from "./chatbot-page";

import data from "./test-data";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default function App() {

  // const [data, setData] = React.useState(undefined);

  // const getData = () => {
  //   Axios.get("http://localhost:4125/articles")
  //     .then(response => {
  //       console.log(response.data);
  //       setData(response.data);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  const [password, setPassword] = React.useState("");
  const [userData, setUserData] = React.useState(JSON.parse(sessionStorage.getItem("user")));

  const navigate = useNavigate();

  const location = useLocation();

  const saveUserData = (userData) => {
    sessionStorage.setItem("user", JSON.stringify(userData));
    setUserData(userData);
  };

  const signOut = () => {
    sessionStorage.removeItem("user");
    setUserData(undefined);
    navigate("/");
  }

  // useEffect(() => {
  //   getArticleData();
  // }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])

  // const unlock = () => {
  //   Axios({
  //     method: "post",
  //     url: API + "/account/unlock",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Accept": "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //     data: {
  //       "password": password,
  //     }
  //   })
  //     .then(response => {
  //       setUnlocked(true);
  //     })
  //     .catch(error => {
  //       console.log(error.response.data.error)
  //     });
  // }

  // const API = "http://localhost:4000"
  const API = "https://www.api.lawlibrary.ai"

  let routes;
  if (true) {
    routes =
      <Routes>
        <Route path="/" exact element={<LandingPage API={API} />} />
        <Route path="/sign-up" exact element={<SignUpPage API={API} userData={userData} saveUserData={saveUserData} to="/verify-email" />} />
        <Route path="/sign-in" exact element={<SignInPage API={API} userData={userData} saveUserData={saveUserData} to="/home" />} />
        <Route path="/welcome" exact element={<WelcomePage />} />
        <Route path="/verify-email" exact element={<VerifyEmailPage />} />
        <Route path="/verify-email-expired-link" exact element={<VerifyEmailExpiredLinkPage />} />
        <Route path="/account" exact element={<AccountPage API={API} userData={userData} saveUserData={saveUserData} signOut={signOut} />} />
        <Route path="/home" exact element={<HomePage API={API} userData={userData} />} />
        {/* <Route path="/ask" exact element={<ChatbotPage />} /> */}
        {/* <Route path="/visualize" exact element={<TestPage />} /> */}
        {/* <Route path="/read" exact element={<ViewDocumentPage data={data} />} /> */}
        {/* <Route path="/pricing" exact element={<PricingPage />} /> */}
      </Routes>
  }

  return (
    <AppWrapper>
      <Header userData={userData} signOut={signOut}/>
      {routes}
      <Footer/>
    </AppWrapper>
  );
};
