import React, { useState, useEffect, useRef } from "react";
import { Parallax } from "react-scroll-parallax";
import styled from "styled-components";
import theme from "./theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: ${theme.spacing.phone};
  padding-bottom: ${theme.spacing.phone};
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    padding-top: ${theme.spacing.tablet};
    padding-bottom: ${theme.spacing.tablet};
  }
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    padding-top: ${theme.spacing.computer};
    padding-bottom: ${theme.spacing.computer};
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ItemNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ItemName = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.color.primary};
  line-hight: 1;
  letter-spacing: 3px;
  text-transform: uppercase;
`;

const ItemNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: ${theme.color.primary};
  color: white;
  margin-right: 24px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 400;
`;

const ItemContent = styled.div`
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 300;
  color: ${theme.color.paragraph};
  line-height: 1.5;
  margin-left: 11.5px;
  border-left: 1.5px solid rgba(220, 220, 220, 1);
  padding-left: calc(11.5px + 24px);
  max-width: 400px;
`;

const ItemBreak = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  color: ${theme.color.title};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

const ItemPoint = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const ItemBulletPoint = styled.div`
  position: absolute;
  top: 2px;
  left: calc(-12px - 24px - 9px);
  width: 10px;
  height: 10px;
  background-color: rgba(200, 200, 200, 1);
  border-radius: 15px;
  border: 4px solid ${theme.color.background};
`;

const Feature = (props) => {

  return (
    <Wrapper>
      <Item>
        <ItemNameWrapper>
          <ItemNumber>
            1
          </ItemNumber>
          <ItemName>
            Search
          </ItemName>
        </ItemNameWrapper>
        <ItemContent>
          <ItemPoint>
            <ItemBulletPoint /> <b>Ask AI</b> for all documents that meet a set of criteria.
          </ItemPoint>
          <ItemPoint>
            <ItemBulletPoint /> <b>Ask AI</b> to recommend documents that are most relevant to your legal question.
          </ItemPoint>
          <ItemPoint>
            <ItemBulletPoint /> <b>Ask AI</b> to recommend documents that are most relevant to your legal argument.
          </ItemPoint>
        </ItemContent>
      </Item>
      <Item>
        <ItemNameWrapper>
          <ItemNumber>
            2
          </ItemNumber>
          <ItemName>
            Analyze
          </ItemName>
        </ItemNameWrapper>
        <ItemContent>
          <b>Visualize</b> how relevant documents connect to each other using a citation map.
        </ItemContent>
      </Item>
      <Item>
        <ItemNameWrapper>
          <ItemNumber>
            3
          </ItemNumber>
          <ItemName>
            Annotate
          </ItemName>
        </ItemNameWrapper>
        <ItemContent>
          Ask AI for documents that are most relevant to your legal question.
        </ItemContent>
      </Item>
    </Wrapper>
  )

}

export default Feature;
