import React from "react";
import styled from "styled-components";
import { PageWrapper } from "./wrapper-elements";
import Header from "./header";
import Footer from "./footer";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  margin-top: 96px;
`;

const HomePage = (props) => {

  return (
    <PageWrapper>
      <Wrapper>
        Home page.
      </Wrapper>
    </PageWrapper>
  )
}

export default HomePage;
