import styled from "styled-components";
import theme from "./theme.json";

const PageWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  min-height: 50vh;
  background-color: white;
  margin-top: calc(96px + 48px);
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    margin-top: 0px;
  }
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

export { PageWrapper, ContentWrapper }; 
