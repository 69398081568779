import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import TextBox from "./text-box";
import { Button } from "./button-elements";
import { FormGrid, FormElement, FormInstructions, FormMessage } from "./form-elements";
import { PageWrapper } from "./wrapper-elements";
import { Block } from "./block-elements";
import { Heading } from "./text-elements";
import theme from "./theme";

const SignUpForm = (props) => {

  const { API, saveUserData, to } = props;

  let [inviteCode, setInviteCode] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [passwordCheck, setPasswordCheck] = useState("");
  let [message, setMessage] = useState("");

  const navigate = useNavigate();

  const signUp = () => {
    if (password === passwordCheck) {
      setMessage("");
      Axios({
        method: "post",
        url: API + "/user/sign-up",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          "invite_code": inviteCode,
          "first_name": firstName,
          "last_name": lastName,
          "email": email,
          "password": password,
        }
      })
        .then(response => {
          console.log(response.data)
          if (response.data.error !== 'Please enter a valid invite code.') {
            navigate(to);
          }
          // setConfirmationOn(true);
          // callback(response.data);
          // setMessage("");
          // console.log(response.data);
        })
        .catch(error => {
          console.log(error.response.data.error)
          setMessage(error.response.data.error);
        });
    } else {
      setMessage("The passwords you entered don't match. Please check them and try again.");
    }
  }

  return (
    <PageWrapper>
      <Block>
        <FormGrid>
          <Heading>
            Sign up
          </Heading>
          <FormInstructions>
            The beta version of LawLibrary.AI is currently invite-only. If you have an invite code, but haven't created an account yet, please sign up below to start using the app.
          </FormInstructions>
          <FormElement>
            <TextBox type="text" value={inviteCode} label="Invite Code" note=
              "Please enter a valid invite code." onChange={setInviteCode} />
          </FormElement>
          <FormElement>
            <TextBox type="text" value={firstName} label="First name" onChange={setFirstName} />
          </FormElement>
          <FormElement>
            <TextBox type="text" value={lastName} label="Last name" onChange={setLastName} />
          </FormElement>
          <FormElement>
            <TextBox type="email" value={email} label="Email" onChange={setEmail} />
          </FormElement>
          <FormElement>
            <TextBox type="password" value={password} label="Password" note="Passwords must have at least 8 characters and have at least one lower case letter, one uppercase letter, one number, and one special character." onChange={setPassword} />
          </FormElement>
          <FormElement>
            <TextBox type="password" value={passwordCheck} label="Confirm password" onChange={setPasswordCheck} />
          </FormElement>
          {/* <FormInstructions>
        By signing up, you agree to our <b>Terms of Service</b> and our <b>Privacy Policy.</b>
      </FormInstructions> */}
          <FormElement>
            <Button onClick={signUp}>
              Sign up
            </Button>
          </FormElement>
          <FormMessage style={{ "display": message === "" ? "none" : "flex" }}>
            {message}
          </FormMessage>
        </FormGrid>
      </Block>
    </PageWrapper>
  )
}

export default SignUpForm;
