import React, { useState, useEffect, useRef } from "react";
import { Parallax } from "react-scroll-parallax";
import styled from "styled-components";
import TextLoop from "react-text-loop";
import { Button } from "./button-elements";
import Axios from "axios";
import Menu from "./menu";
import Footer from "./footer";
import theme from "./theme";
import Flowchart from "./flowchart";
import Header from "./header";
import { Paragraph, Heading, Tagline, Highlight } from "./text-elements";
import { Block, BlockSeparator, BlockTitle, BlockParagraph } from "./block-elements";
import { PageWrapper } from "./wrapper-elements";
import { FormGrid, FormRow, FormElement, FormMessage } from "./form-elements";
import TextBox from "./text-box";

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-top: ${theme.spacing.phone};
  margin-bottom: ${theme.spacing.phone};
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    margin-top: ${theme.spacing.tablet};
    margin-bottom: ${theme.spacing.tablet};
  }
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    margin-top: ${theme.spacing.computer};
    margin-bottom: ${theme.spacing.computer};
  }
`;

const ButtonGroup = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 24px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    max-width: 700px;
    padding-left: 0px;
  }
`;

const ButtonWrapper = styled.div`
  margin-right: 24px;
`;

const HalfBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    width: 50vw;
  }
`;

const BlockGrid = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100vw;
  overflow: hidden;
  margin-top: ${theme.spacing.phone};
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    margin-top: ${theme.spacing.tablet};
  }
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    margin-top: ${theme.spacing.computer};
  }
`;

const WideBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-bottom: ${theme.spacing.text_correction};
`;

const BlockImageWrapper = styled(Parallax)`
  background-color: white;
  border-radius: 16px;
  max-width: 600px;
  width: calc(100vw - 48px);
  margin-top: ${theme.spacing.phone};
  margin-bottom: ${theme.spacing.phone};
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    width: calc(100vw - 48px);
    margin-top: ${theme.spacing.tablet};
    margin-bottom: ${theme.spacing.tablet};
  }
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    width: calc(50vw - 48px);
    margin-top: ${theme.spacing.computer};
    margin-bottom: ${theme.spacing.computer};
  }
`;

const BlockImage = styled.img`
  width: 100%;
  border-radius: 16px;
  box-shadow: ${theme.shadow};
  vertical-align: top;
`;

const FeatureWrapper = styled(Parallax)`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${theme.spacing.margins};
  margin-bottom: ${theme.spacing.text_correction};
  min-width: 0px;
  max-width: 450px;
  width: calc(100vw - 48px);
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    min-width: 450px;
    width: calc(50vw - 48px);
    margin-top: 0px;
  }
`;

const List = styled.ul`
  padding-left: 32px;
  margin-top: -4px;
`;

const ListItem = styled.li`
  width: 100%;
  font-weight: 300;
  line-height: 1.7;
  color: ${theme.color.paragraph};
  font-size: 16px;
  margin-bottom: 16px;
  // &::before {
  //   content: '';
  //   display: block;
  //   margin-top: -4px;
  // }
  // &::after {
  //   content: '';
  //   display: block;
  //   margin-bottom: -12px;
  // }
`;

const BlockRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
`;

const ParallaxTarget = styled.div`
  position: absolute;
  top: -96px;
`;

const ParallaxWrapper = styled(Parallax)`

`;

const Circle = styled.span`
  display: inline-block;
  background-color: ${theme.color.primary};
  color: white;
  line-height: 1;
  font-size: 13px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-right: 16px;
  padding: 8px;
  font-weight: 400;
  text-align: right;
`;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.3);
  background-color: ${theme.color.background};
  padding-bottom: ${theme.spacing.margins};
  padding-top: ${theme.spacing.phone};
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    padding-top: ${theme.spacing.tablet};
  }
  @media only screen and (min-width: ${theme.breakpoints.computer}) {
    padding-top: ${theme.spacing.computer};
  }
`;

const WaitlistWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 12px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    max-width: 700px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

// const Flowchart = styled.img`
//   width: 100%;
//   margin-top: 24px;
//   @media only screen and (min-width: ${theme.breakpoints.tablet}) {
//     max-width: 900px;
//     margin-top: 48px;
//   }
//   @media only screen and (min-width: ${theme.breakpoints.computer}) {
//     max-width: 900px;
//     margin-top: 96px;
//   }
// `;

const Feature = (props) => {

  let { heading, paragraph, tagline, items, imageFile, flipped } = props;

  const { width } = useWindowDimensions();

  const targetRef = useRef();

  const [targetElement, setElement] = React.useState();
  useEffect(() => {
    setElement(targetRef.current);
  }, []);

  let imageTranslateX;
  let imageTranslateY;
  let textTranslateX;
  let textTranslateY;

  if (flipped) {
    imageTranslateX = ["-100px", "0px", "easeInOutQuad"];
    imageTranslateY = ["0px", "0px", "easeInOutQuad"];
    textTranslateX = ["0px", "0px", "easeInOutQuad"];
    textTranslateY = ["0px", "0px", "easeInOutQuad"];
  } else {
    imageTranslateX = ["100px", "0px", "easeInOutQuad"];
    imageTranslateY = ["0px", "0px", "easeInOutQuad"];
    textTranslateX = ["0px", "0px", "easeInOutQuad"];
    textTranslateY = ["0px", "0px", "easeInOutQuad"];
  }

  if (width < 992) {
    flipped = true;
  }

  let list;
  if (items) {
    list =
      <List>
        {items.map((item, i) => {
          return (
            <ListItem key={i}>
              {item}
            </ListItem>
          )
        })}
      </List>
  }

  let text =
    <HalfBlock color={theme.color.background}>
      <FeatureWrapper targetElement={targetElement} translateX={textTranslateX} translateY={textTranslateY}>
        <ParallaxWrapper targetElement={targetElement} translateX={textTranslateX} translateY={["50px", "0px", "easeInOutQuad"]}>
          <Tagline>
            {tagline}
          </Tagline>
        </ParallaxWrapper>
        <ParallaxWrapper targetElement={targetElement} translateX={textTranslateX} translateY={["100px", "0px", "easeInOutQuad"]}>
          <Heading>
            {heading}
          </Heading>
        </ParallaxWrapper>
        <ParallaxWrapper targetElement={targetElement} translateX={textTranslateX} translateY={["150px", "0px", "easeInOutQuad"]}>
          <Paragraph>
            {paragraph}
          </Paragraph>
        </ParallaxWrapper>
        <ParallaxWrapper targetElement={targetElement} translateX={textTranslateX} translateY={["200px", "0px", "easeInOutQuad"]}>
          {list}
        </ParallaxWrapper>
      </FeatureWrapper>
    </HalfBlock>

  let image =
    <HalfBlock color={theme.color.background}>
      <BlockImageWrapper targetElement={targetElement} translateX={imageTranslateX} translateY={imageTranslateY}>
        <BlockImage src={imageFile} />
      </BlockImageWrapper>
    </HalfBlock>

  let content;
  if (flipped) {
    content =
      <BlockRow>
        <ParallaxTarget ref={targetRef} />
        {/* <FeatureBackgroundFlipped src="/feature-background-blurred-1.png" /> */}
        {image}
        {text}
      </BlockRow>
  } else {
    content =
      <BlockRow>
        <ParallaxTarget ref={targetRef} />
        {/* <FeatureBackground src="/feature-background-blurred-1.png" /> */}
        {text}
        {image}
      </BlockRow>
  }

  return (
    content
  );
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const LandingPage = (props) => {

  const { API } = props;

  const [email, setEmail] = React.useState("")
  const [message, setMessage] = React.useState("")

  const width = getWindowDimensions().width;

  const joinWaitlist = () => {
    console.log("join waitlist")
    setMessage("");
    Axios({
      method: "post",
      url: API + "/user/join-waitlist",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "email": email,
      }
    })
      .then(response => {
        // console.log(response.data)
        setEmail("");
        setMessage(response.data.message);
      })
      .catch(error => {
        console.log(error.response.data.error)
        setMessage(error.response.data.error);
      });
  }


  return (
    <PageWrapper>
      <TitleWrapper>
        <BlockTitle>
          <span>Powerful, intuitive EU legal research tools for </span>
          <TextLoop interval={3000}>
            <div>
              lawyers
            </div>
            <div>
              paralegals
            </div>
            <div>
              law students
            </div>
            <div>
              companies
            </div>
            <div>
              prosecutors
            </div>
            <div>
              judges
            </div>
            <div>
              governments
            </div>
            <div>
              researchers
            </div>
          </TextLoop>
        </BlockTitle>
        <BlockParagraph style={{ color: theme.color.paragraph }}>
          LawLibrary.AI is an cutting-edge platform for EU legal research that <Highlight>recommends</Highlight> legal authorities that speak to your legal questions or arguments, <Highlight>analyzes</Highlight> how they connect to each other, and <Highlight>annotates</Highlight> them with highlights, tags, and notes tailored to your task. It's like EUR-Lex, but better.
        </BlockParagraph>
        <WaitlistWrapper>
          <FormElement>
            <TextBox
              label="Get launch updates and early access!"
              value={email}
              onChange={setEmail}
              placeholder="Email"
              type="email"
            />
          </FormElement>
          <FormElement>
            <Button inverted onClick={joinWaitlist}>
              Get early access!
            </Button>
          </FormElement>
          <FormMessage style={{ "display": message === "" ? "none" : "flex" }}>
            {message}
          </FormMessage>
        </WaitlistWrapper>
      </TitleWrapper>
      <Menu />
      <BlockSeparator shape={3} topColor="white" bottomColor="light" />
      <Block backgroundColor="light">
        <BlockGrid>
          <WideBlock>
            <BlockTitle>
              Legal research, simplified
            </BlockTitle>
            {/* <BlockParagraph>
              Legal research is the process of finding legal authorities — like EU treaties, directives, regulations, and CJEU decisions — that answer a legal question or support a legal argument.
            </BlockParagraph> */}
            <BlockParagraph>
              Have you ever done a keyword search on <Highlight style={{ background: theme.color.title, color: "white " }}>EUR-Lex</Highlight> and gotten literally hundreds or thousands of results? <Highlight style={{ background: theme.color.title, color: "white " }}>Not helpful.</Highlight> Where do you even start?
            </BlockParagraph>
            <BlockParagraph>
              How are you supposed to figure out which court cases best answer your question or support your argument? How are you supposed to synthesize all those cases into a coherent answer or argument? How are you supposed to know which parts of a 400+ paragraph judgment have what you need?
            </BlockParagraph>
            <BlockParagraph>
              Legal research is complex and time-consuming, and correctness is paramount. But LawLibrary.AI provides powerful, end-to-end research tools that make legal research <Highlight>simpler</Highlight>, <Highlight>faster</Highlight>, and <Highlight>more accurate</Highlight>.
            </BlockParagraph>
            {/* <Flowchart /> */}
          </WideBlock>
        </BlockGrid>
      </Block>
      <BlockSeparator shape={4} topColor="light" bottomColor="white" flipY={true} />
      <Block backgroundColor="white">
        <WideBlock color={theme.color.background}>
          <BlockTitle>
            Why LawLibrary.AI?
          </BlockTitle>
          <BlockParagraph>
            AI is going to revolutionize legal research. There's no getting around it. But we're taking a different approach than other companies.
          </BlockParagraph>
          <BlockParagraph>
            Other companies offer AI legal assistants and claim they can accurately answer all of your legal questions. But even the best AI assistants often hallucinate — they can confidently provide inaccurate or incomplete answers. <Highlight style={{ background: theme.color.title, color: "white " }}>Don't risk it.</Highlight>
          </BlockParagraph>
          <BlockParagraph>
            An AI legal assistant is no replacement for your own judgment. LawLibrary.AI uses AI <Highlight>responsibly</Highlight> to create powerful, intuitive <Highlight>research tools</Highlight> that empower <Highlight>you</Highlight> to efficiently answer your legal questions and support your legal arguments.
          </BlockParagraph>
        </WideBlock>
      </Block>
      <BlockSeparator shape={3} topColor="white" bottomColor="light" flipY={true} />
      <Block backgroundColor="light">
        <WideBlock color={theme.color.background}>
          <BlockTitle>
            How it works
          </BlockTitle>
          <BlockParagraph>
            <Highlight>LawLibrary.AI</Highlight> streamlines the process of legal research. We <Highlight>recommend</Highlight> legal authorities that speak to your question or support your argument so you don't have to wade through hundreds of search results.
          </BlockParagraph>
          <BlockParagraph>
            We <Highlight>analyze</Highlight> how relevant documents connect to each other so you can see the bigger picture, identify good law, and decide what to focus on.
          </BlockParagraph>
          <BlockParagraph>
            And we <Highlight>annotate</Highlight> these documents with highlights, tags, and notes specific to your question or argument so you can quickly find exactly what you need.
          </BlockParagraph>
        </WideBlock>
        <BlockGrid>
          {/* <Feature
            tagline="AI-powered search"
            heading="Intelligent answers, with sources"
            paragraph="Leverage our state-of-the-art AI legal assistant to answer your legal questions, streamline your research process, and speed up your workflow."
            items={[
              "Unlike general-purpose AI chatbots, it consults and cites relevant legal sources, reducing halucinations.",
              "It has live access to our expansive legal database — updated daily — so it's always up to date.",
              "It thinks step-by-step and self-critiques its responses before answering, leading to better responses."
            ]}
            imageFile="/app-AI-assistant.svg"
            flipped={false}
          /> */}
          <Feature
            tagline="AI-powered recommendations"
            heading="Don't miss something important"
            paragraph="Forget about keywords or search filters. Ask a question or summarize your legal argument and LawLibrary.AI will recommend the legal documents you need."
            items={[
              "Don't mess around with clunky, frustrating search forms. Just ask a question in natural language.",
              "Leverage our extensive database — it's more complete than official sources, and it's updated daily.",
              "Don't know what law applies? Summarize your case facts and LawLibrary.AI will suggest relevant law."
            ]}
            imageFile="/app-recommend.svg"
            flipped={false}
          />
          <Feature
            tagline="AI-powered analysis"
            heading="Make sense of complexity"
            paragraph="Go beyond a list of search results. LawLibrary.AI visualizes how relevant documents are connected and estimates the precedential value of cases so you can see the bigger picture and decide which documents to explore further."
            items={[
              "Explore an interactive citation map tailored to your task to see how the case law has developed.",
              "Ask AI to estimate the precedential value of cases to identify the most persuasive ones to cite.",
              "Ask AI to generate context-specific summaries of documents to help you prioritize what to read.",
            ]}
            imageFile="/app-analyze.svg"
            flipped={true}
          />
          <Feature
            tagline="AI-powered annotations"
            heading="Read efficiently and with purpose"
            paragraph="Filter out the noise. Focus on what's important. Read legal documents with AI-powered, task-specific annotations using our clean, intuitive interface."
            items={[
              "Ask AI to summarize a section or find paragraphs that answer your question or support your argument.",
              "Ask AI to create highlights, tags, and notes specific to your task so you can quickly find what you need.",
              "Download your custom-annotated document with a citation (PDF, TXT, CSV, XML, or HTML).",
            ]}
            imageFile="/app-annotate.svg"
            flipped={false}
          />
        </BlockGrid>
      </Block>
      <BlockSeparator shape={3} topColor="light" bottomColor="white" flipY={true} />
      <Block backgroundColor="white">
        <WideBlock color={theme.color.background}>
          <BlockTitle>
            About LawLibrary.AI
          </BlockTitle>
          <BlockParagraph>
            <Highlight>LawLibrary.AI</Highlight> is developing innovative research tools that leverage artificial intelligence (AI) and machine learning (ML) to streamline the process of European Union (EU) legal research.
          </BlockParagraph>
          <BlockParagraph>
            LawLibrary.AI was founded by <Highlight>Josh Fjelstul</Highlight>. Josh is a political scientist and data scientist who specializes in European Union (EU) law, the Court of Justice of the European Union (CJEU), and quantitative methods.
          </BlockParagraph>
          <BlockParagraph>
            Josh has a BBA from the Canfield Business Honors Program (CBHP) at the McCombs School of Business at the University of Texas at Austin and a Ph.D. in Political Science from Emory University. He's conducted quantitative research on EU law and the CJEU at leading research universities, including Emory University, Washington University in St. Louis, the University of Geneva, and the ARENA Center for European Studies at the University of Oslo.
          </BlockParagraph>
        </WideBlock>
      </Block>
      {/* <Block backgroundColor="light">
        <BlockGrid>
          <WideBlock color={theme.color.background}>
            <BlockTitle>
              How it works.
            </BlockTitle>
          </WideBlock>
          <Feature
            tagline="AI Assistant"
            heading="Intelligent answers, with sources"
            paragraph="Leverage our state-of-the-art AI legal assistant to answer your legal questions, streamline your research process, and speed up your workflow."
            items={[
              "Unlike general-purpose AI chatbots, it consults and cites relevant legal sources, reducing halucinations.",
              "It has live access to our expansive legal database — updated daily — so it's always up to date.",
              "It thinks step-by-step and self-critiques its responses before answering, leading to better responses."
            ]}
            imageFile="/app-AI-assistant.svg"
            flipped={false}
          />
          <Feature
            tagline="Reading Room"
            heading="Legal research, reimagined"
            paragraph="Search our expansive collection of EU legal documents using our clean, intuitive interface, featuring cutting-edge AI-powered research tools."
            items={[
              "Use our AI-created tags — assigned to every paragraph — to quickly find topics, concepts, citations, actors, and more.",
              "Chat with any document — our AI assistant will answer questions, summarize sections, and help you find things.",
              "Add your own bookmarks, highlights, and notes — then export an annotated version (PDF, TXT, CSV, XML)."
            ]}
            imageFile="/app-reading-room.svg"
            flipped={true}
          />
        </BlockGrid>
      </Block> */}
    </PageWrapper>
  )
}

export default LandingPage;
