import React from "react";
import styled from "styled-components";
import Page from "./page";
import { Button, ButtonWrapperRightAligned } from "./button-elements";
import HeaderLogo from "./header-logo";
import DocumentParagraph from "./document-paragraph";
import SearchPage from "./search-page";
import PageTemplate from "./page-template";
import IconButton from "./icon-button";

import {
  PencilSquareIcon as NoteIcon,
  MagnifyingGlassIcon as SearchIcon,
  ArrowDownTrayIcon as DownloadIcon,
  AdjustmentsHorizontalIcon as SettingsIcon,
  MapIcon as OutlineIcon,
  BookmarkIcon as BookmarkIcon,
  ChevronUpIcon as ToTopIcon,
  TagIcon as TagIcon,
  ClipboardIcon as CopyIcon,
  LanguageIcon as LanguageIcon,
  FlagIcon as ReportErrorIcon,
  InformationCircleIcon as InfoIcon,
  ChatBubbleBottomCenterTextIcon as ChatIcon,
  QuestionMarkCircleIcon as HelpIcon,
  ShareIcon as ConnectionsIcon,
  BookOpenIcon as CitationIcon,
  LinkIcon as LinkIcon,
  ChartBarIcon as DataIcon,
  Bars3Icon as MenuIcon,
} from "@heroicons/react/24/outline";

import data from "./example-directive";

const DocumentWrapper = styled.div`
  position: fixed;
  top: calc(80px + 72px + 2px);
  bottom: calc(68px + 1px);
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
`;

const Document = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 800px;
  margin-bottom: 96px;
  margin-top: 96px;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 16px;
  padding-left: 16px;
  border-left: ${props => props.selected ? "1px solid rgba(0, 0, 0, 0.85)" : "1px solid rgba(0, 0, 0, 0.1)"};
  cursor: pointer;
`;

const Number = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 14px;
  min-width: 32px;
`;

const ParagraphWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 16px;
`;

const Paragraph = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.4;
  padding-left: ${props => props.selected ? "calc(16px - 0.5px)" : "16px"};
  padding-right: ${props => props.selected ? "calc(16px - 0.5px)" : "16px"};
  padding-top: ${props => props.selected ? "calc(14px - 0.5px)" : "14px"};
  padding-bottom: ${props => props.selected ? "calc(14px - 0.5px)" : "14px"};
  border-radius: 8px;
  background-color: rgba(250, 250, 250, 1);
  border: ${props => props.selected ? "1.5px solid rgba(38, 38, 38, 1)" : "1px solid rgba(230, 230, 230, 1)"};
`;

const Section = styled.div`
  display: flex;
`;

; const Divider = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 36px;
  margin-left: 8px;
  margin-right: 8px;
`;

const Header = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background: white;
  height: 80px;
  padding-left: 48px;
  padding-right: 48px;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const TopMenu = styled.div`
  position: fixed;
  top: 81px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 48px;
  padding-right: 48px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const DocumentNameWrapper = styled.span`

`;

const DocumentName = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

const SelectedItem = styled.span`
  font-size: 16px;
  font-weight; 400;
  margin-left: 24px;
  line-height: 1;
`;

const BottomMenu = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  padding-top: 16px;
  background-color: white;
  padding-left: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Message = styled.div`
  position: fixed;
  bottom: calc(68px + 16px);
  left: 16px;
  padding: 16px;
  width: 350px;
  background-color: rgba(250, 250, 250, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
`;

const TopButton = styled.div`
  position: fixed;
  right: calc(48px - 8px);
  bottom: calc(68px + 1px + 16px);
`;

const TagSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: -8px;
  margin-left: 16px;
`;

const TagSectionLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const TagRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: -8px;
  margin-left: 16px;
`;

const Tag = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-right: 8px;
  margin-bottom: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  color: rgba(38, 38, 38, 1);
  background-color: white;
  border: 1.5px solid rgba(38, 38, 38, 1);
  border-radius: 32px;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: rgba(38, 38, 38, 1);
    border: 1.5px solid rgba(38, 38, 38, 1);
  }
`;

const parseItems = (data, settings, parentSelected, callback) => {

  let content = [];

  data.map((data_i, i) => {
    if (data_i.type === "heading" || data_i.type === "paragraph") {
      content.push(
        <Item i={i} data={data_i} settings={settings} parentSelected={parentSelected} callback={callback} />
      )
    }
  })

  return (
    content
  );
};

const Item = (props) => {

  const { i, data, settings, parentSelected, callback } = props;

  const [isSelected, setIsSelected] = React.useState(false);

  let children;
  if (data.children) {
    children =
      parseItems(data.children, settings, parentSelected || isSelected, callback);
  }

  let citations;
  if (data.citations && settings.citationsOn) {
    citations =
      <TagRow>
        {data.citations.map((tag, i) => {
          return (
            <Tag key={i}>
              {tag}
            </Tag>
          )
        })}
      </TagRow>
  }

  let tags;
  if (data.tags && settings.tagsOn) {
    tags =
      <TagRow>
        {data.tags.map((tag, i) => {
          return (
            <Tag key={i}>
              {tag}
            </Tag>
          )
        })}
      </TagRow>
  }

  let number;
  if (data.number) {
    number = data.number
  }

  let content;
  if (data.type === "heading") {
    content =
      <Paragraph selected={isSelected || parentSelected}>
        <Heading>
          {settings.selectedLanguage === "english" ? data.english[0] : data.french[0]}
        </Heading>
      </Paragraph>
  }
  if (data.type === "paragraph") {
    content =
      <Paragraph selected={isSelected || parentSelected}>
        {number} {settings.selectedLanguage === "english" ? data.english[0] : data.french[0]}
      </Paragraph>
  }

  return (
    <ItemWrapper
      id={data.label}
      key={i}
      style={{ borderLeft: data.level === 1 ? "none" : "" }}
    >
      <ParagraphWrapper
        onMouseEnter={() => {
          callback(data.label);
          setIsSelected(true);
        }}
        onMouseLeave={() => {
          callback("");
          setIsSelected(false);
        }}
      >
        {content}
        {citations}
        {tags}
      </ParagraphWrapper>
      {children}
    </ItemWrapper>
  );
};

const ViewDocumentPage = (props) => {

  // const { data } = props;

  const [selectedLanguage, setSelectedLanguage] = React.useState("english");
  const [search, setSearch] = React.useState(false);
  const [outline, setOutline] = React.useState(false);
  const [citationList, setCitationList] = React.useState(false);
  const [bookmarkPanelOn, setBookmarkPanelOn] = React.useState(false);
  const [citationsOn, setCitationsOn] = React.useState(true);
  const [tagsOn, setTagsOn] = React.useState(false);
  const [notesOn, setNotesOn] = React.useState(false);
  const [bookmarksOn, setBookmarksOn] = React.useState(false);
  const [connectionsOn, setConnectionsOn] = React.useState(false);
  const [selectedLabel, setSelectedLabel] = React.useState("");

  // let documentData = data.filter((item) => {
  //   return (item.document_id === "ECLI:EU:C:2012:756" && item.language === language);
  // });

  let searchPage;
  if (search) {
    searchPage =
      <SearchPage />
  }

  let settings = {
    selectedLanguage: selectedLanguage,
    tagsOn: tagsOn,
    notesOn: notesOn,
    citationsOn: citationsOn
  };

  return (
    <Page>
      <Header>
        <Section>
          <HeaderLogo />
        </Section>
        <Section>
          <ButtonWrapperRightAligned>
            <Button inverted>
              Subscribe
            </Button>
          </ButtonWrapperRightAligned>
          <ButtonWrapperRightAligned>
            <Button>
              Sign up
            </Button>
          </ButtonWrapperRightAligned>
          <ButtonWrapperRightAligned>
            <Button>
              Sign in
            </Button>
          </ButtonWrapperRightAligned>
        </Section>
      </Header>
      <TopMenu>
        <DocumentNameWrapper>
          <DocumentName>
            {data[0].title}
          </DocumentName>
          <SelectedItem>
            {selectedLabel}
          </SelectedItem>
        </DocumentNameWrapper>
        <Section>
          <IconButton>
            <MenuIcon />
          </IconButton>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <IconButton>
            <ChatIcon />
          </IconButton>
          <IconButton>
            <OutlineIcon />
          </IconButton>
          <IconButton>
            <DataIcon />
          </IconButton>
          <IconButton>
            <SettingsIcon />
          </IconButton>
        </Section>
      </TopMenu>
      <DocumentWrapper id="document-wrapper">
        <Document>
          {parseItems(data, settings, false, setSelectedLabel)}
        </Document>
      </DocumentWrapper>
      {/* <Document>
        {documentData.map((item, i) => {
          if (item.type === "title") {
            return (
              <Title>
                {item.text}
              </Title>
            )
          } else {
            return (
              <DocumentParagraph text={item.text} paragraphNumber={item.paragraph_number} />
            )
          }
        })}
      </Document> */}
      <BottomMenu>
        <Section>
          {/* <Button inverted={selectedLanguage === "french"} onClick={() => setSelectedLanguage("french")}>
            French
          </Button>
          <Button inverted={selectedLanguage === "english"} onClick={() => setSelectedLanguage("english")}>
            English
          </Button> */}
          <IconButton inverted={citationsOn} onClick={() => {
            if (selectedLanguage === "english") {
              setSelectedLanguage("french")
            }
            if (selectedLanguage === "french") {
              setSelectedLanguage("english")
            }
          }}>
            <LanguageIcon />
          </IconButton>
        </Section>
        <Divider />
        <Section>
          <IconButton onClick={() => setCitationsOn(!citationsOn)}>
            <CitationIcon />
          </IconButton>
          <IconButton inverted={tagsOn} onClick={() => setTagsOn(!tagsOn)}>
            <TagIcon />
          </IconButton>
          <IconButton inverted={connectionsOn} onClick={() => setConnectionsOn(!connectionsOn)}>
            <ConnectionsIcon />
          </IconButton>
          <IconButton inverted={bookmarksOn} onClick={() => setBookmarksOn(!bookmarksOn)}>
            <BookmarkIcon />
          </IconButton>
          <IconButton inverted={notesOn} onClick={() => setNotesOn(!notesOn)}>
            <NoteIcon />
          </IconButton>
        </Section>
        <Divider />
        <Section>
          <IconButton>
            <LinkIcon />
          </IconButton>
          <IconButton>
            <DownloadIcon />
          </IconButton>
          {/* <IconButton>
            <InfoIcon />
          </IconButton>
          <IconButton>
            <HelpIcon />
          </IconButton> */}
        </Section>
        <Divider />
        <Section>
          <IconButton onClick={() => {
            document.getElementById("document-wrapper").scrollTo({ top: 0, behavior: "smooth" });
          }}>
            <ToTopIcon />
          </IconButton>
        </Section>
      </BottomMenu>
      {/* <Message>
        Please select a subset of the document to tag (section, paragraph, item, etc.).
      </Message> */}
      {searchPage}
      {/* <TopButton>
        <Button onClick={() => {
          document.getElementById("document-wrapper").scrollTo({ top: 0, behavior: "smooth" });
        }}>
          Top
        </Button>
      </TopButton> */}
    </Page>
  )
}

export default ViewDocumentPage;
