import styled from "styled-components";
import theme from "./theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 340px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.color.label};
  margin-bottom: 12px;
  &::before {
    content: '';
    display: block;
    margin-top: -3px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -3px;
  }
`;

const TextBox = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  color: ${theme.color.text};
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 24px;
  padding-right: 24px;
  border: 1.5px solid ${theme.color.line};
  border-radius: 6px;
  outline: none;
  &:hover {
    border: 1.5px solid ${theme.color.line_dark};
  }
  &:focus {
    border: 1.5px solid ${theme.color.line_dark};
  }
  &::placeholder {
    color: ${theme.color.placeholder};
  }
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${theme.color.text_light};
  margin-top: 12px;
  line-height: 1.5;
  &::before {
    content: '';
    display: block;
    margin-top: -5px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -5px;
  }
`;

const TextBoxComponent = (props) => {
  const { type, value, label, onChange, note, placeholder } = props;

  let labelObject;
  if (label) {
    labelObject =
      <Label>
        {label}
      </Label>
  }

  let noteObject;
  if (note) {
    noteObject = 
    <Note>
      {note}
    </Note>
  }

  return (
    <Wrapper>
      {labelObject}
      <TextBox placeholder={placeholder} type={type} value={value} onChange={(e) => onChange(e.target.value)} />
      {noteObject}
    </Wrapper>
  )
}

export default TextBoxComponent;
