import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "./theme";

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3;
  color: ${theme.color.heading};
  text-align: left;
  margin-bottom: 48px;
  font-size: 24px;
  text-align: ${props => props.$centered ? "center" : "left"};
  &::before {
    content: '';
    display: block;
    margin-top: -10px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -10px;
  }
  @media (min-width: 888px) {
    font-size: 42px;
    margin-bottom: 96px;
  };
`;

const Heading = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: ${props => props.$inverted?  "white" : theme.color.heading};
  margin-top: 0px;
  margin-bottom: ${theme.spacing.text};
  text-align: ${props => props.$centered ? "center" : "left"};
  &::before {
    content: '';
    display: block;
    margin-top: -5px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
`;

const Tagline = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1;
  color: ${theme.color.tagline};
  margin-top: 0px;
  margin-bottom: ${theme.spacing.text};
  &::before {
    content: '';
    display: block;
    margin-top: -1px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -2px;
  }
`;

const Paragraph = styled.div`
  width: 100%;
  font-weight: 300;
  line-height: 1.7;
  color: ${props => props.$inverted? "white" : theme.color.paragraph};
  font-size: 16px;
  text-align: ${props => props.$centered ? "center" : "left"};
  margin-top: 0px;
  margin-bottom: ${theme.spacing.text};
  &::before {
    content: '';
    display: block;
    margin-top: -5px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
`;

const Highlight = styled.span`
  background-color: ${theme.color.primary};
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
`;

const TextLink = styled(Link)`
  font-weight: 600;
  color: rgba(40, 40, 45, 1);
  padding-bottom: 1px;
  &:hover {
    border-bottom: 1.5px solid rgba(40, 40, 45, 1);
  }
`;

export { Title, Heading, Paragraph, Highlight, TextLink, Tagline };
