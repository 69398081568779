import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "./theme";

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  z-index: 500;
`;

const Image = styled.img`
  // margin-right: 16px;
`;

const Logo = (props) => {

  const { logoHeight, wordmarkHeight, inverted } = props;

  return (
    <Wrapper style={{ height: logoHeight }} to="/">
      <Image src={ inverted ? "logo-inverted-2.svg" : "logo-2.svg" } style={{ height: logoHeight }}></Image>
      {/* <Text style={{ fontSize: wordmarkHeight, color: inverted ? "white" : theme.color.title }}>LawLibrary.AI</Text> */}
    </Wrapper>
  )
}

export default Logo;
