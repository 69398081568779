import styled from "styled-components";
import theme from "./theme";
import { Link } from "react-router-dom";

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonWrapperRightAligned = styled.div`
  margin-left: 16px;
`;

const ButtonWrapperLeftAligned = styled.div`
  margin-right: 16px;
`;

const ButtonWrapperCenterAligned = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.inverted ? "white" : theme.color.button} !important;
  background-color: ${props => props.inverted ? theme.color.button : "unset"};
  border: 1.5px solid ${theme.color.button};
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  cursor: pointer;
  transition-duration: 0.2s;
  text-decoration: none !important;
  white-space: nowrap;
  &:hover {
    color: ${props => props.inverted ? "white" : theme.color.button_dark};
    background-color: ${props => props.inverted ? theme.color.button_dark : theme.color.button_light};
    border-color: ${props => props.inverted ? theme.color.button_dark : theme.color.button};
  }
`;

const InternalLinkButton = styled(Link)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.inverted ? "white" : theme.color.button} !important;
  background-color: ${props => props.inverted ? theme.color.button : "unset"};
  border: 1.5px solid ${theme.color.button};
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  cursor: pointer;
  transition-duration: 0.2s;
  text-decoration: none !important;
  white-space: nowrap;
  &:hover {
    color: ${props => props.inverted ? "white" : theme.color.button_dark};
    background-color: ${props => props.inverted ? theme.color.button_dark : theme.color.button_light};
    border-color: ${props => props.inverted ? theme.color.button_dark : theme.color.button};
  }
`;

const ExternalLinkButton = styled.a`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.inverted ? "white" : theme.color.button} !important;
  background-color: ${props => props.inverted ? theme.color.button : "unset"};
  border: 1.5px solid ${theme.color.button};
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  cursor: pointer;
  transition-duration: 0.2s;
  text-decoration: none !important;
  white-space: nowrap;
  &:hover {
    color: ${props => props.inverted ? "white" : theme.color.button_dark};
    background-color: ${props => props.inverted ? theme.color.button_dark : theme.color.button_light};
    border-color: ${props => props.inverted ? theme.color.button_dark : theme.color.button};
  }
`;

export { Button, InternalLinkButton, ExternalLinkButton, ButtonWrapperRightAligned, ButtonWrapperLeftAligned, ButtonWrapperCenterAligned, ButtonRow, ButtonGroup };
