import styled from "styled-components";

const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  min-width: 48px;
  max-height: 48px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  margin-right: 16px;
  line-height: 1;
`;

const UserAvater = styled(Avatar)`
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
`;

const AIAvater = styled(Avatar)`
  background-color: rgba(0, 0, 0, 1);
  color: white;
`;

export default function ChatAvatar(message) {
  if (message.role === "user") {
    return (
      <UserAvater>
        ME
      </UserAvater>
    );
  }

  return (
    <AIAvater>
      AI
    </AIAvater>
  );
}
