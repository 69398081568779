// React components
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NetworkGraph from "./network-graph";
// import data from "./network-data.json";
import data from "./network-data.json";

const Page = styled.div`
  position: fixed;
  z-index: 6000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const DatabaseDiagramPage = (props) => {

  return (
    <Page>
      <NetworkGraph edgeData={data.edges} nodeData={data.nodes} />
    </Page>
  );
};

export default DatabaseDiagramPage;
