import React from "react";
import styled from "styled-components";
import { Button } from "./button-elements";
import TextBox from "./text-box";

const Page = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
`;

const Blur = styled.div`
  position: fixed;
  top: calc(80px + 68px + 2px);
  bottom: calc(68px + 1px);
  left: 0px;
  right: 0px;
  z-index: 50;
  backdrop-filter: blur(5px);
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

const SettingsPage = (props) => {

  const [query, setQuery] = React.useState("")

  return (
    <Blur>
      <Page>
        <FormWrapper>
          <TextBox width="400px" type="text" placeholder="Search" value={query} onChange={setQuery} />
          <ButtonWrapper>
            <Button>
              Search
            </Button>
          </ButtonWrapper>
        </FormWrapper>
      </Page>
    </Blur>
  );
}

export default SettingsPage;
