import React from "react";
import { PageWrapper } from "./wrapper-elements";
import { Heading, TextLink } from "./text-elements";
import { Block } from "./block-elements";
import { FormInstructions, FormGrid } from "./form-elements";

const WelcomePage = (props) => {

  return (
    <PageWrapper>
      <Block>
        <FormGrid>
          <Heading>
            Welcome!
          </Heading>
          <FormInstructions>
            Thanks for verifying your email adresss. Your account is now active. Please <TextLink to="/sign-in">sign in</TextLink> to get started.
          </FormInstructions>
        </FormGrid>
      </Block>
    </PageWrapper>
  )
}

export default WelcomePage;
