import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import TextBox from "./text-box";
import { Heading } from "./text-elements";
import { Button } from "./button-elements";
import { TextLink } from "./text-elements";
import { Block } from "./block-elements";
import { PageWrapper } from "./wrapper-elements";
import { FormGrid, FormElement, FormMessage, FormInstructions } from "./form-elements.js";
import theme from "./theme";

const SignInForm = (props) => {

  const { API, saveUserData, to } = props;

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [message, setMessage] = useState("");

  const navigate = useNavigate();

  const logIn = () => {
    Axios({
      method: "post",
      url: API + "/user/sign-in",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "email": email,
        "password": password,
      }
    })
      .then(response => {
        setMessage("You've successfully signed in.");
        saveUserData(response.data);
        console.log(response.data)
        navigate(to);
      })
      .catch(error => {
        if (error.response) {
          setMessage(error.response.data.error);
        }
      });
  }

  return (
    <PageWrapper>
      <Block backgroundColor="white">
        <FormGrid>
          <Heading>
            Sign in
          </Heading>
          <FormInstructions>
            The beta version of LawLibrary.AI is currently invite-only. If you have an invite code, but haven't created an account yet, please < TextLink to="/sign-up">sign up</TextLink> to get started. If you've already signed up, please sign in below to start using the app.
          </FormInstructions>
          <FormElement>
            <TextBox type="text" value={email} label="Email" onChange={setEmail} />
          </FormElement>
          <FormElement>
            <TextBox type="password" value={password} label="Password" onChange={setPassword} />
          </FormElement>
          <FormElement>
            <Button onClick={logIn}>
              Sign in
            </Button>
          </FormElement>
          <FormMessage style={{ "display": message === "" ? "none" : "flex" }}>
            {message}
          </FormMessage>
        </FormGrid>
      </Block>
    </PageWrapper>
  )
}

export default SignInForm;
