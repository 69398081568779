import React from "react";
import { PageWrapper } from "./wrapper-elements";
import { Heading, TextLink } from "./text-elements";
import { Block } from "./block-elements";
import { FormInstructions, FormGrid } from "./form-elements";

const VerifyEmailPage = (props) => {

  return (
    <PageWrapper>
      <Block>
        <FormGrid>
          <Heading>
            Verify your email
          </Heading>
          <FormInstructions>
            We just sent you an email with a link to verify your email address. Please click on the link within the next 10 minutes to activate your account.
          </FormInstructions>
        </FormGrid>
      </Block>
    </PageWrapper>
  )
}

export default VerifyEmailPage;
