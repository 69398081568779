import React from "react";
import { PageWrapper } from "./wrapper-elements";
import { Heading, TextLink } from "./text-elements";
import { Block } from "./block-elements";
import { FormInstructions, FormGrid } from "./form-elements";

const VerifyEmailExpiredLinkPage = (props) => {

  return (
    <PageWrapper>
      <Block>
        <FormGrid>
          <Heading>
            Verify your email
          </Heading>
          <FormInstructions>
            This verification link has expired. Please <TextLink to="/sign-in">sign in</TextLink> so we can send you an email with another link to verify your email address. Please click on the link within the next 10 minutes to activate your account.
          </FormInstructions>
        </FormGrid>
      </Block>
    </PageWrapper>
  )
}

export default VerifyEmailExpiredLinkPage;
