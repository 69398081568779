import React from "react";
import styled from "styled-components";
import PageTemplate from "./page-template";
import { Button, ButtonRow, ButtonWrapperCenterAligned } from "./button-elements";
import NetworkGraph from "./network-graph";
import data from "./example-citation-data.json";
// import data from "./network-data.json";

const SelectedDocument = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export default function TestPage(props) {

  const [selected, setSelected] = React.useState(undefined);

  const bottomMenuContent =
    <ButtonRow>
      <ButtonWrapperCenterAligned>
        <Button>
          Citations
        </Button>
      </ButtonWrapperCenterAligned>
      <ButtonWrapperCenterAligned>
        <Button>
          Tags
        </Button>
      </ButtonWrapperCenterAligned>
    </ButtonRow>

  const topMenuContent =
    <SelectedDocument>
      {selected}
    </SelectedDocument>

  const pageContent =
    <div>
      <NetworkGraph edgeData={data.edges} nodeData={data.nodes} callback={setSelected} />
    </div>

  return (
    <PageTemplate bottomMenuContent={bottomMenuContent} topMenuContent={topMenuContent} pageContent={pageContent} />
  );
};
