import styled from "styled-components";

const ButtonWrapper = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
`;

const IconButton = (props) => {

  const { onClick } = props;

  return (
    <ButtonWrapper>
      <Button onClick={onClick}>
        <IconWrapper>
          {props.children}
        </IconWrapper>
      </Button>
    </ButtonWrapper>
  )
}

export default IconButton;
