import React from "react";
import styled from "styled-components";
import Page from "./page";
import Button from "./button-elements";
import Chip from "./chip";

const Wrapper = styled.div`
  display: flex;
  direction: row;
  width: 700px;
`;

const Number = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 100px;
  margin-right: 16px;
  margin-top: 8px;
  font-size: 36px;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.15);
`;

const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // border-right: 1px solid rgba(0, 0, 0, 0.1);
  // margin-bottom: 16px;
  // padding-right: 16px;
`;

const Paragraph = styled.div`
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 10px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  background: white;
  transition-duration: 0.2s;
  border-radius: 8px;
  cursor: pointer;
  // border: 1px solid rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.8);
  background: white;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
    // border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
  margin-left: 12px;
`;

const chips = [
  { value: "Monetary policy", type: "topic"},
  { value: "Stability and Growth Pact", type: "topic"},
  { value: "European Commission", type: "actor"},
  { value: "United Kingdom", type: "actor"},
  { value: "Council of the European Union", type: "actor"},
  { value: "Proportionality", type: "concept"},
  { value: "Direct effect", type: "concept"},
];

const DocumentParagraph = (props) => {

  const { text, paragraphNumber } = props;

  return (
    <Wrapper>
      <Number>
        {paragraphNumber === "000" ? "" : paragraphNumber}
      </Number>
      <ParagraphWrapper>
        <Paragraph>
          {text}
        </Paragraph>
        {/* <ChipWrapper>
          {chips.map((item, i) => {
            return (
              <Chip value={item.value} type={item.type} />
            )
          })}
        </ChipWrapper> */}
      </ParagraphWrapper>
    </Wrapper>
  )
}

export default DocumentParagraph;
