// React components
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import runNetworkGraph from "./generate-network-graph";

const Container = styled.div`
  position: fixed;
  top: 80px;
  bottom: 80px;
  right: 0px;
  left: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border-radius: 8px;
  overflow: hidden;
  background-color: white;
  // border: 1px solid rgba(0, 0, 0, 0.1);
`;

const NetworkGraph = (props) => {

  const { edgeData, nodeData, nodeHoverTooltip, callback } = props;

  const containerRef = React.useRef(null);

  React.useEffect(() => {

    let destroyFn;

    if (containerRef.current) {

      const { destroy } = runNetworkGraph(containerRef.current, edgeData, nodeData, callback);

      destroyFn = destroy;
    }

    return destroyFn;
  }, []);

  return (
    <Container id="network-container" ref={containerRef} />
  )
}

export default NetworkGraph;
