import styled from "styled-components";
import { Button } from "./button-elements"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 48px;
  left: 250px;
  right: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 700px;
`;

const Input = styled.textarea`
  box-sizing: border-box;
  display: flex;
  box-shadow: none;
  border-radius: 8px;
  width: 700px;
  height: 96px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 24px;
  resize: none;
  outline: none;
  &:focus {
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 15px;
    padding-right: 15px;
    border: 2px solid rgba(0, 0, 0, 1);
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

export default function ChatInput(props) {
  return (
    <Wrapper>
      <Form
        onSubmit={props.handleSubmit}
      >
        <Input
          autoFocus
          name="message"
          placeholder="Type a message"
          value={props.input}
          onChange={props.handleInputChange}
        />
        <ButtonWrapper>
          <Button
            disabled={props.isLoading}
            inverted={true}
            type="submit"
          >
            Submit
          </Button>
        </ButtonWrapper>
      </Form>
    </Wrapper>
  );
}
